import React from 'react'
import Layout from '../../components/layout'
import { DefinitionsCreator } from '../../components/ChallengeCreators'

const DefinitionsCreatorPage = ({ challengeId }) => (
  <Layout>
    <DefinitionsCreator challengeId={challengeId} />
  </Layout>
)

export default DefinitionsCreatorPage
